import React from 'react';
import SupportBar from '../../Components/SupportBar';
import Navbar from '../../Components/Navbar';
import Footer from '../../Components/Footer';
import Hero from '../../Components/Hero';
import { sihsHero } from '../../Components/Data/heroData';
// import sihsLogo from '../../Components/Images/sihs-logo.jpg';
// import { Link } from 'react-router-dom';
import Sidebar from '../../Components/Sidebar';
import './Academics.css';
import ScrollToTop from '../../Components/ScrollToTop';

const SIHS = () => {
    return (
        <div className='page'>
            <ScrollToTop />
            <SupportBar />
            <Navbar />
            <Sidebar />
            <Hero data={sihsHero} />
            <div className='school-intro'>
                <div className='school-about'>
                    <h1>Saint Ignatius High School</h1>
                    <h3>"In all things, to serve"</h3>
                    <p>Saint Ignatius High School, founded in 2012 by the Society of Jesuits, builds upon the legacy of Ecole Primaire Saint Ignace (EPSI Kibagabaga) with a profound commitment to fostering success, excellence, and dignity in education. The school aims to nurture boys and girls into compassionate leaders who are well-equipped to make meaningful contributions to society.</p>
                    <p>Emphasizing a holistic approach to learning, Saint Ignatius High School serves day scholars and implements the national curriculum established by the Rwanda Education Board (REB). Enrollment is specifically offered in Senior 1, 2, 4, and 5, with a deliberate decision to exclude Senior 3 and 6 to ensure a focused and supportive academic environment.</p>
                    <p>At the A' Level, the school provides a diverse array of subject combinations, each designed to inspire students’ intellectual curiosity and prepare them for their future endeavors:</p>
                    <div>
                        <p><span className='combination'>MCB:</span> Maths, Biology & Chemistry</p>
                        <p><span className='combination'>MCE:</span> Maths, Computer Science & Economics</p>
                        <p><span className='combination'>MPC:</span> Maths, Physics & Computer Science</p>
                        <p><span className='combination'>PCB:</span> Physics, Chemistry & Biology</p>
                    </div>
                    <p>Through this structured academic framework, Saint Ignatius High School strives to cultivate well-rounded individuals who are not only prepared for academic success but are also inspired to lead with integrity and purpose in an ever-changing world.</p>
                </div>
                <div className='school-contact-info'>
                    <div className='school-contacts'>
                        <h1>Contact Info</h1>
                        <div>
                            <p>346C+R59, KG 19 Ave,</p>
                            <p>Kigali, Rwanda</p>
                        </div>
                        <div>
                            <p>+250 781 887 400</p>
                            <p>info-sihs@stignatiusschools.rw</p>
                        </div>
                        <p className='highlight'>Mon - Fri: 09:00A.M - 05:00P.M</p>
                    </div>
                    {/* <Link to='/apply-to-stignatius' className='button donor' aria-label='Link to Study With Us page'>Apply</Link>
                    <div className='school-logo'>
                        <img src={sihsLogo} alt='sihs-logo' />
                    </div> */}
                </div>
            </div>
            <Footer />
        </div>
    )
};

export default SIHS;