import React from 'react'
import { Link } from 'react-router-dom';
import Counter from '../Counter';
import { numbers } from '../Data/counterData';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import './HomeIntro.css';

const HomeIntro = () => {
  return (
    <div className='home-intro'>
      <div className='home-intro-left'>
        <h4 className='school-name'>Saint Ignatius School</h4>
        <p className='welcome-msg'>About Our School</p>
      </div>
      <div className='home-intro-right'>
        <div className='home-intro-stats'>
          {
            numbers.map((number, index) => {
              return <Counter key={index} data={number} />
            })
          }
        </div>
        <p className='home-intro-desc'>St Ignatius School was established by the Society of Jesuits in 2008 as private Catholic school and the first Jesuit school in Rwanda. Throughout our great history, St Ignatius School Rwanda has offered access to a wide range of academic opportunities. As a national leader in nursery, primary and high school education, the school has pioneered change in the education sector.</p>
        <Link to='/about' className='read-more' aria-label='Link to About page'>Learn More <ArrowForwardIosOutlinedIcon /></Link>
      </div>
    </div>
  )
};

export default HomeIntro;
