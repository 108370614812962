import React from 'react';
import { useLocation } from 'react-router';
import './Hero.css';

const Hero = ({ data }) => {
    const location = useLocation();
    const style = location.pathname === '/' ? 'hero-home' : 'hero-other';

    return (
        <div className={style}>
            <img src={data.img} alt='hero-background' className='hero-img'/>
            <div className='hero-content'>
                {data.desc && <h3 className='hero-desc'>{data.desc}</h3>}
                <h1 style={location.pathname === '/student-life' ? { textDecoration: 'underline #02ff7c', textUnderlineOffset: '1rem'} : {}} className='hero-title'>{data.title}</h1>
                {data.loc && <p className='hero-loc'>{data.loc}</p>}
                {data.button && <button className='hero-button'>{data.button}</button>}
            </div>
        </div>
    )
};

export default Hero;
