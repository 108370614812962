import React from 'react';
import './Article.css';
import ScrollToTop from '../../Components/ScrollToTop';
import SupportBar from '../../Components/SupportBar';
import Navbar from '../../Components/Navbar';
import Sidebar from '../../Components/Sidebar';
import { articles } from '../../Components/Data/Articles';
import { useParams } from 'react-router';
import Footer from '../../Components/Footer';

const Article = () => {
    const { articleId } = useParams();
    const data = articles.find(article => article.id === Number(articleId));

    return (
        <div className='page'>
            <ScrollToTop/>
            <SupportBar/>
            <Navbar/>
            <Sidebar/>
            <div className='article-full'>
                <img className='article-full-img' src={data.img} alt='article-cover'/>
                <div className='article-full-title'>
                    <h1>{data.title}</h1>
                    <p>{data.date}</p>
                </div>
                <p className='article-full-desc'>{data.desc}</p>
            </div>
            <Footer/>
        </div>
    )
};

export default Article;
