import React from 'react';
import styled from 'styled-components';

const Section = styled.div`
    display: flex;
    width: 100vw;

    @media screen and (max-width: 768px) {
        justify-content: center;
        padding: 4rem 0;
    }

    @media screen and (max-width: 480px) {
        justify-content: flex-start;
    }
`;

const ColumnLeft = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 50%;
    padding: 2rem 4rem;
    order: ${({ reverse }) => (reverse ? '2' : '1')};

    h3 {
        font-size: 2rem;
        font-weight: 600;
        margin-bottom: 1rem;
    }

    p {
        color: #505050;
        line-height: 1.7;
    }

    @media screen and (max-width: 768px) {
        padding: 0 4rem;
    }

    @media screen and (max-width: 480px) {
        padding: 0 3rem;
        width: 80%
    }
`;

const ColumnRight = styled.div`
    order: ${({ reverse }) => (reverse ? '1' : '2')};

    img{
        width: 50vw;
        height: 100%;
        object-fit: cover;
    }

    @media screen and (max-width: 768px) {
        display:none
    }
`;

const AboutSection = ({ data }) => {
    return (
        <Section className='about-section'>
            <ColumnLeft reverse={data.reverse}>
                <h3>{data.title}</h3>
                <p>{data.desc}</p>
            </ColumnLeft>
            <ColumnRight reverse={data.reverse}>
                <img src={data.img} alt='section-picture'/>
            </ColumnRight>
        </Section>
    )
};

export default AboutSection;
