import React from 'react';
import SupportBar from '../../Components/SupportBar';
import Navbar from '../../Components/Navbar';
import Hero from '../../Components/Hero';
import { homeHero } from '../../Components/Data/heroData';
import HomeIntro from '../../Components/HomeIntro';
import AdmissionCall from '../../Components/AdmissionCall';
// import Event from '../../Components/Event';
// import { events } from '../../Components/Data/EventsData';
import { articles } from '../../Components/Data/Articles';
import { testimonials } from '../../Components/Data/TestimonialData';
import News from '../../Components/News';
// import Tweets from '../../Components/Tweets';
import Footer from '../../Components/Footer';
import { Link } from 'react-router-dom';
import Sidebar from '../../Components/Sidebar';
import './Home.css';
import Testimonial from '../../Components/Testimonial';
import ScrollToTop from '../../Components/ScrollToTop';

const Home = () => {
  return (
    <div className='page'>
      <ScrollToTop/>
      <SupportBar />
      <Navbar />
      <Sidebar />
      <Hero data={homeHero} />
      <HomeIntro />
      <AdmissionCall />
      {/* <div className='update-title'>
        <div className='update-header'>
          <h5>Upcoming Events</h5>
          <Link to='/upcoming-events'>View All Events</Link>
        </div>
        <hr className='horizontal-line'/>
      </div>
      <div className='recent-items'>
        {
          events.slice(0, 3).map((item, index) => {
            return <Event data={item} key={index} />
          })
        }
      </div> */}
      <div className='update-title'>
        <div className='update-header'>
          <h5>News & Updates</h5>
          <Link to='/news-updates'>Read All News</Link>
        </div>
        <hr className='horizontal-line'/>
      </div>
      <div className='recent-items'>
        {
          articles.slice(0, 3).map((article) => <News article={article} key={article.id} />)
        }
      </div>
      <div className='update-title'>
        <div className='update-header'>
          <h5>What Parents Say</h5>
        </div>
        <hr className='horizontal-line'/>
      </div>
      <div className='testimonials'>
        {
          testimonials.map((testimonial, index) => <Testimonial testimonial={testimonial} key={index}/>)
        }
      </div>
      {/* <Tweets/> */}
      <Footer/>
    </div>
  )
};

export default Home;
