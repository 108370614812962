import mission from '../Images/mission.jpg';
import vision from '../Images/vision.jpg';
import values from '../Images/values.jpg';
import philosophy from '../Images/philosophy.jpg';

export const aboutData = [
    {
        title: 'Mission',
        desc: 'Guided by the intellectual tradition of the Society of Jesus, our mission is to educate young men and women to become morally responsible individuals—people for others. We aim to nurture adults who can reflect, discern, and make decisions based on the values of Jesus Christ, who act wisely, and who work competently in service of the common good.',
        img: mission,
        reverse: false
    },
    {
        title: 'Vision',
        desc: 'Our vision is to provide a first-class education for our students. We strive to be a school of excellence in Rwanda and beyond.',
        img: vision,
        reverse: true
    },
    {
        title: 'Core Values',
        desc: 'Everyone involved in the educational community of our schools must keep their focus on Jesus Christ. They should be guided by core values such as excellence (Magis), integrity, faith that promotes justice, service, and absolute respect.',
        img: values,
        reverse: false
    },
    {
        title: 'Philosophy',
        desc: 'At St. Ignatius Schools, we follow a child-centered educational approach grounded in scientific observations from birth through adulthood. We believe that each child is naturally curious and capable of initiating their own learning when placed in a supportive and thoughtfully prepared environment.',
        img: philosophy,
        reverse: true
    }
];