import graduation from '../Images/graduation1.jpg';
import dancing from '../Images/dancing.jpg';
import science from '../Images/science.jpg';

export const events = [
    {
        day: '20',
        month: 'Oct',
        img: graduation,
        title: 'Class of 2024 Graduation',
        time: '11:00 - 14:00',
        location: 'St Ignatius High School'
    },
    {
        day: '19',
        month: 'Sept',
        img: dancing,
        title: 'Culture Day',
        time: '11:00 - 16:00',
        location: 'St Ignatius Primary School'
    },
    {
        day: '15',
        month: 'Aug',
        img: science,
        title: 'Science Day',
        time: '12:00 - 15:00',
        location: 'St Ignatius High School'
    },
    {
        day: '20',
        month: 'Oct',
        img: graduation,
        title: 'Class of 2024 Graduation',
        time: '11:00 - 14:00',
        location: 'St Ignatius High School'
    },
    {
        day: '19',
        month: 'Sept',
        img: dancing,
        title: 'Culture Day',
        time: '11:00 - 16:00',
        location: 'St Ignatius Primary School'
    },
    {
        day: '15',
        month: 'Aug',
        img: science,
        title: 'Science Day',
        time: '12:00 - 15:00',
        location: 'St Ignatius High School'
    },
    {
        day: '20',
        month: 'Oct',
        img: graduation,
        title: 'Class of 2024 Graduation',
        time: '11:00 - 14:00',
        location: 'St Ignatius High School'
    },
    {
        day: '19',
        month: 'Sept',
        img: dancing,
        title: 'Culture Day',
        time: '11:00 - 16:00',
        location: 'St Ignatius Primary School'
    },
    {
        day: '15',
        month: 'Aug',
        img: science,
        title: 'Science Day',
        time: '12:00 - 15:00',
        location: 'St Ignatius High School'
    }
];