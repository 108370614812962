import React from 'react';
import './Facility.css';

const Facility = ({ facility }) => {
    return (
        <div className='facility'>
            <img src={facility.img} alt='facility-picture'/>
            <h3>{facility.title}</h3>
            <p>{facility.desc}</p>
        </div>
    )
};

export default Facility;
