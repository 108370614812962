import React from 'react';
import './AboutIntro.css';

const AboutIntro = () => {
    return (
        <div className='about-intro'>
            <div className='about-intro-title'><h3>Who We Are</h3></div>
            <div className='about-intro-desc'>
                <p>As our former Fr. General Pedro Arrupe noted, “Education for Jesuits is an apostolic instrument.” It is an expression of our prayers and joyful contemplation in action. Our apostolic desire, vision, and serious commitment give us, along with those we collaborate with—students, parents, teachers, support staff, donors, the church, and the country—an opportunity to participate in co-creating the world we envision, inspired by the Spiritual Exercises of St. Ignatius of Loyola. In line with other Jesuit schools globally, the critical objective of our education at St. Ignatius is to educate children who will become women and men for and with others as adults. While our input is local, the definition of our output and outcomes is global. Over the past 474 years, Jesuits have been well-known throughout the world for an educational tradition of excellence, which began when St. Ignatius of Loyola and his companions opened the first Jesuit school in 1548 in Messina, Sicily, Italy.</p>
                <p>Today, our team of approximately 100 teachers, drawn from the top educators in Rwanda and neighboring countries, continues to contextualize this Jesuit educational tradition. We have started attracting teachers from Europe, the USA, and other developed countries to prepare our students in the four Cs that characterize Jesuit education today: competence, compassion, conscience, and commitment. Our teachers instruct students using the Ignatian pedagogy of holistically caring for the whole child (cura personalis) while teaching children to discern critically and act freely. Over 1,600 children who study at our school, together with their passionate parents, testify that the golden balance of our pedagogy is founded on the desire for more, or Magis. Our goal is to create future citizens and professionals who are competitive in academics, strong critical thinkers, and have compassionate hearts to champion spiritual and secular change in the world.</p>
            </div>
        </div>
    )
};

export default AboutIntro;
