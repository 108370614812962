export const links = [
    {
        src: "https://www.youtube.com/embed/PEdb_YHbcjE",
        title: "ECOLE SAINT IGNACE TESTIMONY",
    },
    {
        src: "https://www.youtube.com/embed/NrsJclFT3U4",
        title: "TRIP STUDY CLIP"
    },
    {
        src: "https://www.youtube.com/embed/6p5lhFTeZD4",
        title: "Saint Ignatius High School Graduation Ceremony Class of 2022 2023"
    }
]