import React from 'react';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { createRoot } from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider
} from "react-router-dom";
import { Provider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import { thunk } from 'redux-thunk';
import Home from './Pages/Home';
import About from './Pages/About';
import Admission from './Pages/Admission';
import Support from './Pages/Support';
import StudentLife from './Pages/StudentLife';
import EMSI from './Pages/Academics/emsi';
import EPSI from './Pages/Academics/epsi';
import SIHS from './Pages/Academics/sihs';
import Contact from './Pages/Contact';
import Events from './Pages/Events';
import NewsUpdates from './Pages/NewsUpdates';
// import Administration from './Pages/Administration';
import Projects from './Pages/Projects';
import { openSidebar } from './reducers';
import "./index.css";
import Article from './Pages/Article';

const store = configureStore({
  reducer: {
    openSidebar
  },
  middleware: (getDefaultMiddleware) => [...getDefaultMiddleware(), thunk]
});

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/about",
    element: <About />,
  },
  {
    path: "/study-with-us",
    element: <Admission />
  },
  {
    path: "/support-stignatius",
    element: <Support />
  },
  {
    path: "//life-at-achool",
    element: <StudentLife />
  },
  {
    path: "/ecole-maternelle-st-ignace",
    element: <EMSI />
  },
  {
    path: "/ecole-primaire-st-ignace",
    element: <EPSI />
  },
  {
    path: "/st-ignatius-high-school",
    element: <SIHS />
  },
  {
    path: "/contact",
    element: <Contact />
  },
  {
    path: "/upcoming-events",
    element: <Events />
  },
  {
    path: "/news-updates",
    element: <NewsUpdates />
  },
  {
    path: "/projects",
    element: <Projects />
  },
  {
    path: "/news-updates/article/:articleId",
    element: <Article />
  }
  // {
  //   path: "/administration",
  //   element: <Administration />
  // },
]);

createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
