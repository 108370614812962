import React from 'react';
import SupportBar from '../../Components/SupportBar';
import Navbar from '../../Components/Navbar';
import Hero from '../../Components/Hero';
import { supportHero } from '../../Components/Data/heroData';
import Footer from '../../Components/Footer';
import graceFound from '../../Components/Images/grace-foundation-logo.png';
import learning from '../../Components/Images/mission.jpg';
import running from '../../Components/Images/run.jpg';
import emergency from '../../Components/Images/emergency.jpeg';
import construction from '../../Components/Images/construction.jpg';
import library from '../../Components/Images/library.jpg';
import lab from '../../Components/Images/chem-lab.jpg';
import graduation from '../../Components/Images/graduation1.jpg';
// import Accordion from '../../Components/Accordion';
import Sidebar from '../../Components/Sidebar';
import './Support.css';
import ScrollToTop from '../../Components/ScrollToTop';

const Support = () => {
    return (
        <div className='page'>
            <ScrollToTop/>
            <SupportBar />
            <Navbar />
            <Sidebar/>
            <Hero data={supportHero} />
            <div className='support-intro'>
                <div className='support-about'>
                    <h1>About The Grace Foundation</h1>
                    <p>The mission of the Grace Foundation is to catalyze support for Jesuit education in Rwanda. Our first project, St. Ignatius School, provides an academically excellent education in the Jesuit Catholic tradition to nursery, primary and secondary school students. We are committed to the holistic development of each student's character within a framework of Christian values in order to lay the foundation for a peaceful and just society. The core values of St. Ignatius School: Excellence, Faith, Integrity, Respect.</p>
                    <p>We continue to adapt to new ways of teaching, new disciplines of study and new ways of learning. Your gift enriches the experience of all students by supporting efforts to recruit top teachers, expand academic programs and respond to the emerging needs of our campus and our world.</p>
                    <img src={graceFound} alt='Grace Foundation logo' />
                </div>
                <div className='support-links'>
                    <div className='fund-links'>
                        <h1>Foundation Contact Info</h1>
                        <div>
                            <p>8425 NE 20th Street,</p>
                            <p>Bellevue, WA 98004, USA</p>
                        </div>
                        <div>
                            <p>+1 425 445 6046</p>
                            <p>julie@thegrace-foundation.org</p>
                        </div>
                        <p className='highlight'>Mon - Fri: 09:00A.M - 05:00P.M (UTC-08:00)</p>
                    </div>
                    <div className='momo-code'><p>Momo Donation: *182*8*1*005753#</p></div>
                    <a href='https://www.thegrace-foundation.com/donate' target='_blank' rel='noopener noreferrer' className='button donor' aria-label='Link to Grace Foundation website'>Become A Donor</a>
                </div>
            </div>
            <div className='update-title'>
                <div className='update-header donation'>
                    <h5>Donations Support</h5>
                </div>
                <hr className='horizontal-line' />
            </div>
            <div className='donation-options'>
                <div className='donation-option'>
                    <img src={learning} alt='donation-option' />
                    <div className='caption'>
                        <p>Student Programs</p>
                    </div>
                </div>
                <div className='donation-option'>
                    <img src={lab} alt='donation-option' />
                    <div className='caption'>
                        <p>Labs</p>
                    </div>
                </div>
                <div className='donation-option'>
                    <img src={library} alt='donation-option' />
                    <div className='caption'>
                        <p>Libraries</p>
                    </div>
                </div>
                <div className='donation-option'>
                    <img src={running} alt='donation-option' />
                    <div className='caption'>
                        <p>Sports</p>
                    </div>
                </div>
                <div className='donation-option'>
                    <img src={construction} alt='donation-option' />
                    <div className='caption'>
                        <p>Construction Projects</p>
                    </div>
                </div>
                <div className='donation-option'>
                    <img src={emergency} alt='donation-option' />
                    <div className='caption'>
                        <p>Emergency Fund</p>
                    </div>
                </div>
            </div>
            {/* <div className='update-title'>
                <div className='update-header donation'>
                    <h5>Why Give To St Ignatius?</h5>
                </div>
                <hr className='horizontal-line' />
            </div>
            <Accordion /> */}
            <div className='thank-you-section'>
                <img src={graduation} alt='graduates' />
                <div className='thank-you-note'>
                    <p>Thanks to you, our students are gaining the knowledge, skills and values to create a more humane, just, and sustainable world.</p>
                    <a href='https://www.thegrace-foundation.com/donate' target='_blank' rel='noopener noreferrer' className='button' aria-label='Link to Grace Foundation website'>Become A Donor</a>
                </div>
            </div>
            <Footer />
        </div>
    )
};

export default Support;
