import React from 'react';
// import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
// import InstagramIcon from '@mui/icons-material/Instagram';
// import XIcon from '@mui/icons-material/X';
// import YouTubeIcon from '@mui/icons-material/YouTube';
import { Link } from 'react-router-dom';
import './Footer.css';

const Footer = React.memo(() => {
    return (
        <div className='footer'>
            <div className='quick-links'>
                <div className='footer-address'>
                    <div className='school-footer-details'>
                        <p>Saint Ignatius School</p>
                    </div>
                    <div className='physical-address'>
                        <p>346C+R59, KG 19 Ave,</p>
                        <p>Kigali, Rwanda</p>
                    </div>
                    <div className='online-address'>
                        <p>+250 788 319 868</p>
                        <a href='mailto:info@stignatiusschools.rw' target='_blank' rel='noopener noreferrer' aria-label='Link to Email address'>info@stignatiusschools.rw</a>
                    </div>
                </div>
                <div className='footer-section'>
                    <div className='footer-section-title'>
                        <p>Our School</p>
                    </div>
                    <div className='footer-section-links'>
                        <Link to='/about' aria-label='Link to About page'>About</Link>
                        <Link to='/projects' aria-label='Link to Projects page'>Projects</Link>
                        <Link to='/support-stignatius' aria-label='Link to Support St Ignatius page'>Donate</Link>
                        <Link to='/contact' aria-label='Link to Contact page'>Contact</Link>
                    </div>
                </div>
                <div className='footer-section'>
                    <div className='footer-section-title'>
                        <p>Academics</p>
                    </div>
                    <div className='footer-section-links'>
                        <Link to='/ecole-maternelle-st-ignace' aria-label='Link to Ecole Maternelle St. Ignace page'>Ecole Maternelle St. Ignace</Link>
                        <Link to='/ecole-primaire-st-ignace' aria-label='Link to Ecole Primaire St. Ignace page'>Ecole Primaire St. Ignace</Link>
                        <Link to='/st-ignatius-high-school' aria-label='Link to St. Ignatius High School page'>St. Ignatius High School</Link>
                        {/* <Link to='/apply-to-stignatius' aria-label='Link to Study With Us page'>Apply</Link> */}
                    </div>
                </div>
                <div className='footer-section'>
                    <div className='footer-section-title'>
                        <p>Student Life</p>
                    </div>
                    <div className='footer-section-links'>
                        <Link to='/life-at-achool'>Life At School</Link>
                        <Link to='/news-updates'>Latest News</Link>
                        {/* <Link to='/upcoming-events' aria-label='Link to Upcoming Events page'>Upcoming Events</Link> */}
                    </div>
                </div>
            </div>
            <div className='social-media'>
                <div className='copyright'>
                    <p>Saint Ignatius School © {new Date().getFullYear()}</p>
                </div>
                {/* <div className='social-media-links'>
                    <a href='https://www.facebook.com/EPSI.Kibagabaga' target='_blank' rel='noopener noreferrer' aria-label='Link to Facebook account'><FacebookRoundedIcon/></a>
                    <a href='https://twitter.com/IgnatiusHigh' target='_blank' rel='noopener noreferrer' aria-label='Link to Twitter account'><XIcon/></a>
                    <a href='https://www.instagram.com/epsi_kibagabaga/' target='_blank' rel='noopener noreferrer' aria-label='Link to Instagram account'><InstagramIcon/></a>
                    <a href='https://www.youtube.com/@ecoleprimairesaintignaceki8800' target='_blank' rel='noopener noreferrer' aria-label='Link to Youtube channel'><YouTubeIcon/></a>
                </div> */}
            </div>
        </div>
    )
});

export default Footer;
