import React from 'react';
import DraftsOutlinedIcon from '@mui/icons-material/DraftsOutlined';
import PhoneIcon from '@mui/icons-material/Phone';
import './SupportBar.css';
import { Link } from 'react-router-dom';

const SupportBar = () => {
  return (
    <div className='support-bar'>
      <div className='support-bar-left'>
        <div className='support-bar-email'>
            <DraftsOutlinedIcon/>
            <a href='mailto:rector@stignatiusschools.rw' target="_blank" rel="noopener noreferrer" aria-label='Link to Email page'>info@stignatiusschools.rw</a>
        </div>
        <div className='support-bar-phone'>
            <PhoneIcon/>
            <p>+250 788 319 868</p>
        </div>
      </div>
      <div className='support-bar-right'>
        {/* <Link to='/administration'>Administration</Link> */}
        <Link to='/contact' aria-label='Link to Contact page'>Contact</Link>
        <Link to='/support-stignatius' className='support-link' style={{color:"#fff"}} aria-label='Link to Support St Ignatius page'>Support St Ignatius</Link>
      </div>
    </div>
  )
};

export default SupportBar;
