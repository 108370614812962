import React from 'react';
import SupportBar from '../../Components/SupportBar';
import Navbar from '../../Components/Navbar';
import Footer from '../../Components/Footer';
import Hero from '../../Components/Hero';
import { epsiHero } from '../../Components/Data/heroData';
// import epsiLogo from '../../Components/Images/epsi-logo.jpeg';
// import { Link } from 'react-router-dom';
import Sidebar from '../../Components/Sidebar';
import './Academics.css';
import ScrollToTop from '../../Components/ScrollToTop';

const EPSI = () => {
    return (
        <div className='page'>
            <ScrollToTop />
            <SupportBar />
            <Navbar />
            <Sidebar />
            <Hero data={epsiHero} />
            <div className='school-intro'>
                <div className='school-about'>
                    <h1>Ecole Primaire Saint Ignace</h1>
                    <h3>"Frères et soeurs pour servir"</h3>
                    <p>Ecole Primaire Saint Ignace, established in 2008 as the first Jesuit school in Rwanda, began with an initial cohort of 130 students and has since grown to accommodate 798 students. The school is committed to providing a Christ-centered education, deeply rooted in Jesuit principles such as Cura Personalis (care for the individual), Magis (striving for excellence), and a focus on holistic development.</p>
                    <p>Ecole Primaire Saint Ignace has consistently achieved excellence in the Primary Leaving Examination since 2011, a testament to its rigorous academic standards and dedication to student success. The school not only follows the national curriculum provided by the Rwanda Education Board (REB) but also offers a bilingual learning environment where students are taught in both English and French, equipping them with essential language skills for their future.</p>
                    <p>Moreover, the school emphasizes nurturing well-rounded individuals who are prepared to serve their communities, fostering a sense of responsibility and compassion among its young learners. Ecole Primaire Saint Ignace previously ran the Fenix Project, an initiative that provided psychological assistance and ensured that students received the emotional and mental support they needed to thrive. The principles of this project continue to guide the school’s efforts to promote student well-being.</p>
                </div>
                <div className='school-contact-info'>
                    <div className='school-contacts'>
                        <h1>Contact Info</h1>
                        <div>
                            <p>346C+R59, KG 19 Ave,</p>
                            <p>Kigali, Rwanda</p>
                        </div>
                        <div>
                            <p>+250 781 887 399</p>
                            <p>info-epsi@stignatiusschools.rw</p>
                        </div>
                        <p className='highlight'>Mon - Fri: 09:00A.M - 05:00P.M</p>
                    </div>
                    {/* <div className='school-logo'>
                        <img src={epsiLogo} alt='epsi-logo' />
                    </div>
                    <Link to='/apply-to-stignatius' className='button donor' aria-label='Link to Study With Us page'>Apply</Link> */}
                </div>
            </div>
            <Footer />
        </div>
    )
};

export default EPSI;
