import React from 'react'
import SupportBar from '../../Components/SupportBar';
import Navbar from '../../Components/Navbar';
import Hero from '../../Components/Hero';
import { contactHero } from '../../Components/Data/heroData';
import Footer from '../../Components/Footer';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PhoneIcon from '@mui/icons-material/Phone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Sidebar from '../../Components/Sidebar';
import './Contact.css';
import ScrollToTop from '../../Components/ScrollToTop';

const Contact = () => {
    const [result, setResult] = React.useState("");

    const onSubmit = async (event) => {
        event.preventDefault();
        setResult("Sending....");
        const formData = new FormData(event.target);

        formData.append("access_key", "5c71248c-93ed-4178-9887-9aad4bd567fb");

        const response = await fetch("https://api.web3forms.com/submit", {
            method: "POST",
            body: formData
        });

        const data = await response.json();

        if (data.success) {
            setResult("Message Sent Successfully");
            event.target.reset();
        } else {
            console.log("Error", data);
            setResult(data.message);
        }
    };

    return (
        <div className='page'>
            <ScrollToTop/>
            <SupportBar />
            <Navbar />
            <Sidebar/>
            <Hero data={contactHero} />
            <div className='contact-container'>
                <div className='message-section'>
                    <div>
                        <h1 className='contact-section-header'>LEAVE US A MESSAGE</h1>
                        <p className='sub-title'>AND WE WILL GET BACK TO YOU.</p>
                    </div>
                    <form onSubmit={onSubmit}>
                        <label>Name</label>
                        <input type="text" name="name" placeholder="Enter Your Name" required />
                        <label>Email</label>
                        <input type="email" name="email" placeholder="Enter Your Email" required />
                        <label>Message</label>
                        <textarea name="message" rows="6" placeholder="Enter Your Message" required></textarea>
                        <button type="submit" className="button">Send</button>
                    </form>
                    <span>{result}</span>
                </div>
                <div className='location'>
                    <h1 className='contact-section-header'>ADDRESS</h1>
                    <p><LocationOnIcon/> 346C+R59, KG 19 Ave, Kigali, Rwanda</p>
                    <p><EmailOutlinedIcon/> info@stignatiusschools.rw</p>
                    <p><PhoneIcon/> +250 788 319 868</p>
                </div>
            </div>
            <Footer />
        </div>
    )
};

export default Contact;
