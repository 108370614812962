import React from "react";
import styled from "styled-components";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { setOpen } from '../../actions';

const SidebarContainer = styled.div`
    position: fixed;
    z-index: 999;
    width: 50%;
    height: 100%;
    background: #212121;
    display: grid;
    align-items: center;
    top: 0;
    transition: 0.3s ease-in-out;
    opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};
    right: ${({ isOpen }) => (isOpen ? '0' : '-100%')};

    @media screen and (max-width: 600px) {
        width: 100%;
    }
`;

const Icon = styled.div`
    position: absolute;
    top: 1.2rem;
    right: 1.5rem;
    background: transparent;
    cursor: pointer;
    outline: none;
`;

const CloseIcon = styled(CloseOutlinedIcon)`
    color: #fff;
    height: 40px;
    width: 40px;
`;

const SidebarWrapper = styled.div``;

const SidebarMenu = styled.div`
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    padding-left: 10%;
    margin-bottom: 3rem;

    @media screen and (max-width: 320px) {
        margin-bottom: 1rem;
    }
`;

const SidebarLink = styled(Link)`
    color: #909090;
    font-size: 1.2rem;
    text-decoration: none;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    margin-bottom: 1rem;

    &:hover {
        color: #fff;
    }

    @media screen and (max-width: 320px) {
        font-size: 1rem;
        margin-bottom: 0.5rem;
    }
`;

const BtnWrap = styled.div`
    padding-left: 10%;

    .button:hover {
        background-color: #fff;
        color: #212121;
        transition: all ease-in-out 0.2s;
    }
`

const Sidebar = () => {
    const dispatch = useDispatch();
    const currState = useSelector(state => state.openSidebar.isOpen);

    const toggle = () => {
        dispatch(setOpen(!currState));
    };

    return (
        <SidebarContainer isOpen={currState} onClick={toggle}>
            <Icon onClick={toggle}>
                <CloseIcon />
            </Icon>
            <SidebarWrapper>
                <SidebarMenu>
                    <SidebarLink to="/" aria-label='Link to Home page'>Home</SidebarLink>
                    <SidebarLink to="/about" aria-label='Link to About page'>About</SidebarLink>
                    <SidebarLink to='/ecole-maternelle-st-ignace' aria-label='Link to Ecole Maternelle Saint Ignace page'>Ecole Maternelle Saint Ignace</SidebarLink>
                    <SidebarLink to='/ecole-primaire-st-ignace' aria-label='Link to Ecole Primaire Saint Ignace page'>Ecole Primaire Saint Ignace</SidebarLink>
                    <SidebarLink to='/st-ignatius-high-school' aria-label='Link to Saint Ignatius High School page'>Saint Ignatius High School</SidebarLink>
                    <SidebarLink to='/life-at-achool' aria-label='Link to Life At School page'>Life At School</SidebarLink>
                    <SidebarLink to='/student-life/news-updates' aria-label='Link to News & Updates page'>News & Updates</SidebarLink>
                    {/* <SidebarLink to='/student-life/upcoming-events' aria-label='Link to Upcoming Events page'>Upcoming Events</SidebarLink> */}
                    <SidebarLink to="/projects" aria-label='Link to Projects page'>Projects</SidebarLink>
                    <SidebarLink to="/study-with-us" aria-label='Link to Study With Us page'>Study With Us</SidebarLink>
                    <SidebarLink to="/contact" aria-label='Link to Contact Us page'>Contact Us</SidebarLink>
                </SidebarMenu>
                <BtnWrap>
                    <Link to="/support-stignatius" className="button" aria-label='Link to Support St Ignatius page'>Support St Ignatius</Link>
                </BtnWrap>
            </SidebarWrapper>
        </SidebarContainer>
    );
};

export default Sidebar;