import React from 'react';
import SupportBar from '../../Components/SupportBar';
import Navbar from '../../Components/Navbar';
import Hero from '../../Components/Hero';
import { admissionHero } from '../../Components/Data/heroData';
import HowToApply from '../../Components/HowToApply';
// import TuitionFees from '../../Components/TuitionFees';
import Footer from '../../Components/Footer';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import Sidebar from '../../Components/Sidebar';
import './Admission.css';
import ScrollToTop from '../../Components/ScrollToTop';

const Admission = () => {
    return (
        <div className='page'>
            <ScrollToTop/>
            <SupportBar />
            <Navbar />
            <Sidebar/>
            <Hero data={admissionHero} />
            <HowToApply />
            <div className='important-info'>
                <div className='important-info-left'>
                    <h2>Things To know First</h2>
                    <p>The application process applies for all students applying to any of St Ignatius Schools.</p>
                    <p>You will need:</p>
                    <p className='info-point'><RadioButtonCheckedIcon /> Birth certifate</p>
                    <p className='info-point'><RadioButtonCheckedIcon /> Medical certifate</p>
                    <p className='info-point'><RadioButtonCheckedIcon /> Copy of health insurance</p>
                    <p className='info-point'><RadioButtonCheckedIcon /> Two passport photos</p>
                    <p className='info-point'><RadioButtonCheckedIcon /> Copy of recent school report</p>
                    <p className='info-point'><RadioButtonCheckedIcon /> Copies of both parents'/guardians' IDs</p>
                    <p className='info-point'><RadioButtonCheckedIcon /> The child has to be at least 6 years of age to be admitted to P1.</p>
                </div>
                {/* <div className='important-info-right'>
                    <div>
                        <h2 className='info-title'>Tuition Fees</h2>
                        <TuitionFees />
                    </div>
                    <div className='application-links'>
                        <h2>Ready To Apply?</h2>
                        <div className='links'>
                            <a href='sds' target='_blank' rel='noopener noreferrer' className='button'>Apply</a>
                        </div>
                    </div>
                </div> */}
            </div>
            <Footer />
        </div>
    )
};

export default Admission;
