import React from 'react';
import graduation from '../Images/graduation.jpg';
// import { Link } from 'react-router-dom';
import './AdmissionCall.css';

const AdmissionCall = () => {
    return (
        <div className='admission-call'>
            <div className='adm-call-left'>
                <img src={graduation} alt='graduation-ceremony' />
            </div>
            <div className='adm-call-right'>
                <div className='adm-call-title'>
                    <h3>Our Aim</h3>
                    {/* <p>{new Date().getFullYear()} - {new Date().getFullYear() + 1} applications are now open</p> */}
                </div>
                <p className='adm-call-desc'>We don't just give students an education and experience that sets them up for success in a career. We help them discover a field they are passionate about and aspire to lead.</p>
                {/* <Link to='/apply-to-stignatius' className='button' aria-label='Link to Application page'>Apply Now</Link> */}
            </div>
        </div>
    )
};

export default AdmissionCall;
