import React from 'react';
import { Link } from 'react-router-dom';
import './News.css';

const News = ({ article }) => {
    return (
        <div className='article'>
            <img className='article-img' src={article.img} alt='article-cover'/>
            <div className='article-details'>
                <p className='article-date'>{article.date}</p>
                <p className='article-title'>{article.title}</p>
            </div>
            <Link to={`/news-updates/article/${article.id}`} className='button' aria-label='Link to News article page'>Read More</Link>
        </div>
    )
};

export default News;
