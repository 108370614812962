import React from 'react';
import SupportBar from '../../Components/SupportBar';
import Navbar from '../../Components/Navbar';
import Hero from '../../Components/Hero';
import { eventsHero } from '../../Components/Data/heroData';
import Event from '../../Components/Event';
import { events } from '../../Components/Data/EventsData';
import Footer from '../../Components/Footer';
import Sidebar from '../../Components/Sidebar';
import './Events.css';
import ScrollToTop from '../../Components/ScrollToTop';

const Events = () => {
    return (
        <div className='page'>
            <ScrollToTop/>
            <SupportBar/>
            <Navbar/>
            <Sidebar/>
            <Hero data={eventsHero}/>
            <div className='events'>
                {
                    events.map((item, index) => <Event data={item} key={index}/>)
                }
            </div>
            <Footer/>
        </div>
    )
};

export default Events;
