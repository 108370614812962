import React, { useState } from 'react';
import CountUp from 'react-countup';
import ScrollTrigger from 'react-scroll-trigger';
import './Counter.css';

const Counter = ({ data }) => {
    const [start, setStart] = useState(false);

    return (
        <ScrollTrigger
            onEnter={() => setStart(true)}
        >
            <div className='counter'>
                <h1>
                    { start && <CountUp start={data.count - 50} end={data.count} duration={4} delay={0}/> }
                </h1>
                <p>{data.desc}</p>
            </div>
        </ScrollTrigger>
    )
};

export default Counter;
