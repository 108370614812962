import React from 'react';
import SupportBar from '../../Components/SupportBar';
import Navbar from '../../Components/Navbar';
import Footer from '../../Components/Footer';
import Hero from '../../Components/Hero';
import { emsiHero } from '../../Components/Data/heroData';
// import emsiLogo from '../../Components/Images/emsi-logo.jpeg';
// import { Link } from 'react-router-dom';
import Sidebar from '../../Components/Sidebar';
import './Academics.css';
import ScrollToTop from '../../Components/ScrollToTop';

const EMSI = () => {
    return (
        <div className='page'>
            <ScrollToTop />
            <SupportBar />
            <Navbar />
            <Sidebar />
            <Hero data={emsiHero} />
            <div className='school-intro'>
                <div className='school-about'>
                    <h1>Ecole Maternelle Saint Ignace</h1>
                    <h3>"To love and serve God through your neighbor"</h3>
                    <p>Rooted in the Jesuit intellectual tradition, EMSI aims to nurture young children into morally responsible individuals who reflect, discern, and act according to Christian values. The school supports children and parents through high-quality, inclusive early learning and childcare services, aligning with government initiatives for Early Childhood Development.</p>
                    <p>EMSI fosters an educational community centered on the values of Jesus Christ, ensuring that all members are guided by fundamental Christian principles.</p>
                    <p>Established on October 25, 2021, EMSI is a private nursery school run by the Jesuit Fathers. The school provides a balanced curriculum that covers Language, Pre-Mathematics, Pre-writing, and Arts, supporting intellectual growth while enhancing social development through play and extra-curricular activities. EMSI has seen a steady increase in enrollment, from 62 students in 2021-2022 to 96 students in 2023-2024.</p>
                    <p>The school’s welcoming environment, featuring fruit trees, flowers, and vibrant classrooms, attracts many families, making EMSI a popular choice for early childhood education.</p>
                </div>
                <div className='school-contact-info'>
                    <div className='school-contacts'>
                        <h1>Contact Info</h1>
                        <div>
                            <p>346C+R59, KG 19 Ave,</p>
                            <p>Kigali, Rwanda</p>
                        </div>
                        <div>
                            <p>+250 788 389 564</p>
                            <p>info-emsi@stignatiusschools.rw</p>
                        </div>
                        <p className='highlight'>Mon - Fri: 09:00A.M - 05:00P.M</p>
                    </div>
                    {/* <div className='school-logo'>
                        <img src={emsiLogo} alt='emsi-logo' />
                    </div> */}
                    {/* <Link to='/apply-to-stignatius'className='button donor' aria-label='Link to Study With Us page'>Apply</Link> */}
                </div>
            </div>
            <Footer />
        </div>
    )
};

export default EMSI;