import React from 'react';
import SupportBar from '../../Components/SupportBar';
import Navbar from '../../Components/Navbar';
import Hero from '../../Components/Hero';
import { studentLifeHero } from '../../Components/Data/heroData';
import { facilities } from '../../Components/Data/StudentLifeData';
import Facility from '../../Components/Facility';
import Footer from '../../Components/Footer';
import { links } from '../../Components/Data/YoutubeLinks';
import YoutubeEmbed from '../../Components/YoutubeEmbed';
import Sidebar from '../../Components/Sidebar';
import ScrollToTop from '../../Components/ScrollToTop';
import events from '../../Components/Images/events-traditions.jpg';
import './StudentLife.css';

const StudentLife = () => {
    return (
        <div className='page'>
            <ScrollToTop/>
            <SupportBar />
            <Navbar />
            <Sidebar/>
            <Hero data={studentLifeHero} />
            <div className='traditions'>
                <div className='traditions-left'>
                    <h2 className='traditions-title'>School Life</h2>
                    <h3 className='traditions-subTitle'>Traditions & Values</h3>
                    <p>Saint Ignatius Kindergarten, Primary, and High School, is a Jesuit institution inspired by the teachings of Saint Ignatius of Loyola. The school emphasizes the presence of God in its daily life and work, guiding students and staff to integrate faith, knowledge, and resilience for a bright future. Jesuit education is built on ten core principles: integrating faith with culture, prioritizing care and concern for individuals, fostering values and self-awareness, upholding Christ as a role model, preparing students for active community life, and encouraging engagement in both church and community activities.</p>
                    <p>The school also values collaboration among staff, students, parents, and the broader community, emphasizing adaptability, continuous learning, and the pursuit of excellence. It aims to form individuals who embody the "4Cs"—conscience, competence, compassion, and commitment—preparing them to be agents of positive change and global leaders. The Jesuit education provided at Saint Ignatius is seen as a transformative, world-class experience that equips students to make a meaningful impact in society.</p>
                    <p>The school boasts a range of facilities designed to enhance the educational experience, including dining on campus, which fosters community and nourishment; athletic programs that promote physical fitness and teamwork; and various clubs and extra-curricular activities that encourage creativity and social engagement. Additionally, students have access to state-of-the-art information technology and well-equipped science labs that facilitate hands-on learning and innovation. Safety and security measures are also prioritized, ensuring a safe environment for all students and staff.</p>
                </div>
                <div className='traditions-right'>
                    <img src={events} alt='traditional dance' />
                </div>
            </div>
            <div className='facilities'>
                {
                    facilities.map((facility) => <Facility facility={facility} />)
                }
            </div>
            <div className='embeded-videos'>
                {
                    links.map((link, index) => <YoutubeEmbed link={link} key={index}/>)
                }
            </div>
            <Footer />
        </div>
    )
};

export default StudentLife;
