export const appSteps = [
    {
        step: '1',
        title: 'Complete Registration Form',
        desc: 'Using the registration form provided at the school, fill the form and provide all the necessary details, and documents.',
    },
    {
        step: '2',
        title: 'Submit The Form',
        desc: 'Review your registration and ensure all the information provided is correct and up-to-date. Then proceed to submitting your registration form.'
    },
    {
        step: '3',
        title: 'Admission Test',
        desc: 'Applicants must pass the admission test to determine their academic elegibility to study at St Ignatius.'
    },
    {
        step: '4',
        title: 'Final Decision',
        desc: 'After passing the admission test, applicants will be admitted to St Ignatius depending on how well they did on the test. Applicants will be informed via phone call, email or SMS'
    }
];