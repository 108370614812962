import article1 from '../Images/article1.jpg';
import article2 from '../Images/article2.jpeg';
import article3 from '../Images/article3.jpg';
import article4 from '../Images/article4.jpeg';

export const articles = [
    {
        id: 1,
        img: article1,
        date: 'June 11, 2024',
        title: 'Primary six students visit Rwanda Parliament and Umurishyo Cultural Arts Center',
        desc: `Primary Six students of Ecole Primaire Saint Ignace went on a study trip to the Rwandan Parliament, the Museum for Campaign Against the Genocide at Gishushu, and Umurishyo Art Gallery in Kimironko. Their questions about who, when, and how one becomes a senator or deputy were answered through a digital presentation and an interactive talk in both chambers of parliament. The students then visited Umurishyo Art Gallery, where they had the opportunity to learn about ancient economic activities, tools, crafts, and various traditions.`
    },
    {
        id: 2,
        img: article2,
        date: 'June 5, 2024',
        title: 'Primary five students visit Nyanza District',
        desc: `Primary Five students enjoyed a wonderful study trip to the King's Palace Museum, also known as Rukari. They listened to a detailed presentation on the history of the palace while touring the site. During the visit, they were introduced to various traditional items, including battle weapons, utensils, traditional clothing, and Inyambo cows, among others. The students also had the opportunity to ask questions. After exploring Rukari, they proceeded to Ruhango to attend a Holy Mass at Yezu Nyirimpuhwe.`
    },
    {
        id: 3,
        img: article3,
        date: 'May 28, 2024',
        title: 'Fr. Marc Desmet, SJ, Regional Superior of the European Low Countries, visits St Ignatius School',
        desc: `We were honored to host Father Marc Desmet, SJ, Regional Superior of the European Low Countries, during his visit to our school this morning. He was accompanied by Fr. Fabien Gasigwa, SJ, Regional Superior of the Jesuits of the Rwanda-Burundi Region. After a warm welcome, Fr. Innocent Ruguragu, the school rector; Fr. Emile Nsengimana, the high school director; Sr. Liberata Epimaque, the headmistress of the Nursery; and Fr. Marc Desmet engaged in a meaningful interaction with the students. High school students Isingizwe M.E. Malaika and Muhirwa G. Ian presented a brief history of the school, highlighting why St. Ignatius is an ideal place for them and their peers to grow and thrive through scholarships, academic excellence, and extracurricular activities. The school leadership also discussed development projects and potential collaborations with Jesuit schools in Fr. Marc’s region and globally. Fr. Marc Desmet, SJ, commended the school’s efforts in promoting girls’ education, especially in STEM fields. To mark the occasion, he was invited to plant a tree on the school grounds, embracing St. Ignatius School’s environmental practices inspired by Laudato Si'. The visit concluded with Fr. Marc playing the flute and singing for the nursery students and their teachers.`
    },
    {
        id: 4,
        img: article4,
        date: 'October 14, 2023',
        title: 'Saint Ignatius High School receives award from Rwanda Standand Board(RSB)',
        desc: `In celebration of International World Standards Day 2023, the Rwanda Standards Board (RSB) recognized educational institutions that contribute to the development of quality education. This recognition included an invitation to participate in a national competition on standards. Saint Ignatius High School (SIHS) was honored as one of the top performers in the competition, with three of our students ranking among the six best performers who received awards. These outstanding students will go on to represent Rwanda in the International Standards Olympiad in Korea. RSB expressed gratitude to educational institutions for partnering with them to provide quality and standards training to the youth, highlighting that this will enhance creativity and innovation in the industry in the near future.`,
    }
];