import img1 from '../Images/construction-project.jpg';
import img2 from '../Images/sports-project.jpg';
import img3 from '../Images/innovation-project.jpg';
import img4 from '../Images/classroom-project.jpg';
import img6 from '../Images/computer-case.jpg';
import img7 from '../Images/theater.jpg';
import img9 from '../Images/transportation.jpg';
import img10 from '../Images/fund-project.jpg';
import img11 from '../Images/commerce-project.jpg';

export const ongoing = [
    {
        name: 'Primary & High School Dining Hall',
        progress: '80% Construction | 65% Payment',
        img: img1
    },
    {
        name: 'New Computers',
        progress: '0% Complete',
        img: img6
    },
    {
        name: 'Sports Facilities',
        progress: '0% Complete',
        img: img2
    },
    {
        name: 'Scholarship & Endowment Fund',
        progress: '0% Complete',
        img: img10
    }
];

export const future = [
    {
        name: 'Innovation Hub',
        progress: '0% Complete',
        img: img3
    },
    {
        name: 'Transportation',
        progress: '0% Complete',
        img: img9
    },
    {
        name: 'Nursery Dining Hall',
        progress: '0% Complete',
        img: img4
    },
    {
        name: 'Theater Hall',
        progress: '0% Complete',
        img: img7
    },
    {
        name: 'Commercial Renting space',
        progress: '0% Complete',
        img: img11
    }
];