import React from 'react';
import { Link } from 'react-router-dom';
import './NewsMedium.css';

const NewsMedium = ({ article }) => {
    return (
        <div className='article-medium'>
            <div className='article-medium-img-container'>
                <img src={article.img} alt='article cover' className='article-medium-img' />
            </div>
            <div className='article-medium-details'>
                <div>
                    <h1 className='article-medium-title'>{article.title}</h1>
                    <p className='article-medium-date'>{article.date}</p>
                </div>
                <p className='article-medium-body'>{article.desc.slice(0, 300) + ". . . . ."}</p>
                <Link to={`/news-updates/article/${article.id}`} className='button' aria-label='Link to News article page'>Read More</Link>
            </div>
        </div>
    )
};

export default NewsMedium;
