import React from 'react';
import SupportBar from '../../Components/SupportBar';
import Navbar from '../../Components/Navbar';
import Footer from '../../Components/Footer';
import { ongoing, future } from '../../Components/Data/ProjectsData';
import Project from '../../Components/Project';
import Sidebar from '../../Components/Sidebar';
import './Projects.css';
import ScrollToTop from '../../Components/ScrollToTop';

const Projects = () => {
    return (
        <div className='page'>
            <ScrollToTop/>
            <SupportBar/>
            <Navbar/>
            <Sidebar/>
            <div className='projects'>
                <p className='projects-subtitle'>Where We Are Heading</p>
                <h1 className='projects-header'>Projects</h1>
                <div className='projects-section'>
                    <h2 className='projects-section-header'>Ongoing</h2>
                    <div className='project-list'>
                        {
                            ongoing.map((project, index) => <Project project={project} key={index}/>)
                        }
                    </div>
                </div>
                <div className='projects-section'>
                    <h2 className='projects-section-header'>Future</h2>
                    <div className='project-list'>
                        {
                            future.map((project, index) => <Project project={project} key={index}/>)
                        }
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
};

export default Projects;
