import React from 'react';
import SupportBar from '../../Components/SupportBar';
import Navbar from '../../Components/Navbar';
import Hero from '../../Components/Hero';
import { newsHero } from '../../Components/Data/heroData';
import './NewsUpdates.css';
import Footer from '../../Components/Footer';
import NewsMedium from '../../Components/NewsMedium';
import { articles } from '../../Components/Data/Articles';
import Sidebar from '../../Components/Sidebar';
import './NewsUpdates.css';
import ScrollToTop from '../../Components/ScrollToTop';


const NewsUpdates = () => {
    return (
        <div className='page'>
            <ScrollToTop/>
            <SupportBar/>
            <Navbar/>
            <Sidebar/>
            <Hero data={newsHero}/>
            <div className='articles'>
                {
                    articles.map((article, index) => <NewsMedium article={article} key={index}/>)
                }
            </div>
            <Footer/>
        </div>
    )
};

export default NewsUpdates;
