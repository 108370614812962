import React from 'react';
import { appSteps } from '../Data/AppSteps';
import './HowToApply.css';

const HowToApply = () => {
    return (
        <div className='app-process'>
            <h1 className='app-process-title'>How To Register?</h1>
            <div className='app-steps'>
                {
                    appSteps.map((appStep) => {
                        return (
                            <div className='app-step'>
                                <h1 className='app-step-num'>{appStep.step}</h1>
                                <div>
                                    <h3 className='app-step-title'>{appStep.title}</h3>
                                    <p className='app-step-desc'>{appStep.desc}</p>
                                </div>
                            </div>
                        );
                    })
                }
            </div>
        </div>
    )
};

export default HowToApply;
