import React from 'react';
import SupportBar from '../../Components/SupportBar';
import Navbar from '../../Components/Navbar';
import Hero from '../../Components/Hero';
import { aboutHero } from '../../Components/Data/heroData';
import AboutIntro from '../../Components/AboutIntro';
import AboutSection from '../../Components/AboutSection';
import { aboutData } from '../../Components/Data/AboutData';
import Footer from '../../Components/Footer';
import Sidebar from '../../Components/Sidebar';
import './About.css';
import ScrollToTop from '../../Components/ScrollToTop';

const About = () => {
    return (
        <div className='page'>
            <ScrollToTop/>
            <SupportBar/>
            <Navbar/>
            <Sidebar/>
            <Hero data={aboutHero}/>
            <AboutIntro/>
            <div className='quote'>
                <p>“Education for Jesuits is an apostolic instrument.” - Fr. General Pedro Arrupe, SJ</p>
            </div>
            {
                aboutData.map((data) => <AboutSection data={data}/>)
            }
            <Footer/>
        </div>
    )
};

export default About;
