import React from 'react';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import './Event.css';

const Event = ({ data }) => {
  return (
    <div className='event'>
      <img src={data.img} alt='event-image' className='event-img'/>
      <div className='event-details'>
        <div className='event-date'>
            <p>{data.day}</p>
            <p>{data.month}</p>
        </div>
        <div className='event-desc'>
            <p>{data.title}</p>
            <p><AccessTimeOutlinedIcon/> {data.time}</p>
            <p><LocationOnOutlinedIcon/> {data.location}</p>
        </div>
      </div>
    </div>
  )
}

export default Event
