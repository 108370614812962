export const numbers = [
    {
        count: 331,
        desc: 'Nursery'
    },
    {
        count: 798,
        desc: 'Primary'
    },
    {
        count: 830,
        desc: 'High School'
    }
];