import React from 'react'
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setOpen } from '../../actions';
import MenuIcon from '@mui/icons-material/Menu';
import './Navbar.css';

const Navbar = React.memo(() => {
  const dispatch = useDispatch();
  const currState = useSelector(state => state.openSidebar.isOpen);

  const toggle = () => {
    dispatch(setOpen(!currState));
  };

  return (
    <div className='navbar'>
      <div className='navbar-left'>
        <Link to='/' className='school-name'>Saint Ignatius School</Link>
      </div>
      <div className="menu-icon" onClick={toggle}><MenuIcon sx={{ width: 40, height: 40, color: '#303030'}}/></div>
      <div className='navbar-right'>
        <Link to='/' aria-label='Link to Home page'>Home</Link>
        <Link to='/about' aria-label='Link to About page'>About</Link>
        <div className='dropdown'>Academics
          <div className='submenu'>
            <Link to='/ecole-maternelle-st-ignace' aria-label='Link to Ecole Maternelle Saint Ignace page'>Ecole Maternelle Saint Ignace</Link>
            <Link to='/ecole-primaire-st-ignace' aria-label='Link to Ecole Primaire Saint Ignace page'>Ecole Primaire Saint Ignace</Link>
            <Link to='/st-ignatius-high-school' aria-label='Link to Saint Ignatius High School page'>Saint Ignatius High School</Link>
          </div>
        </div>
        <div className='dropdown'>Student Life
          <div className='submenu'>
            <Link to='/life-at-achool' aria-label='Link to Life At School page'>Life At School</Link>
            <Link to='/news-updates' aria-label='Link to News & Updates page'>News & Updates</Link>
            {/* <Link to='/upcoming-events' aria-label='Link to Upcoming Events page'>Upcoming Events</Link> */}
          </div>
        </div>
        <Link to='/projects' aria-label='Link to Projects page'>Projects</Link>
        <Link to='/study-with-us' aria-label='Link to Study With Us page'>Study With Us</Link>
      </div>
    </div>
  );
});

export default Navbar;
