import img1 from '../Images/hero-home.jpg';
import img2 from '../Images/hero-about.jpg';
import img3 from '../Images/emsi-students.jpg';
import img4 from '../Images/hero-support.jpg';
import img5 from '../Images/clubs.jpg';
import img6 from '../Images/emsi.jpg';
import img7 from '../Images/epsi.jpg';
import img8 from '../Images/sihs.jpg';
import img9 from '../Images/contact1.jpg';
import img10 from '../Images/calendar.jpg';
import img11 from '../Images/news.jpg';
import img12 from '../Images/admin.jpg';

export const homeHero = {
    title: 'Saint Ignatius School',
    desc: 'In All Things To Love And Serve',
    loc: 'Kigali, Rwanda',
    // button: 'Take A Tour',
    img: img1
};

export const aboutHero = {
    title: 'Know Us Better',
    desc: 'About Us',
    img: img2
};

export const admissionHero = {
    title: 'Study With Us',
    desc: 'Admission',
    img: img3
};

export const supportHero = {
    title: 'Give To St Ignatius',
    desc: 'Become a donor',
    img: img4
}

export const studentLifeHero = {
    title: "Student Life",
    img: img5
}

export const emsiHero = {
    title: "Ecole Maternelle Saint Ignace",
    desc: "EMSI",
    img: img6
}

export const epsiHero = {
    title: "Ecole Primaire Saint Ignace",
    desc: "EPSI",
    img: img7
}

export const sihsHero = {
    title: "Saint Ignatius High School",
    desc: "SIHS",
    img: img8
}

export const contactHero = {
    title: "Contact Us",
    desc: "Get In Touch",
    img: img9
}

export const eventsHero = {
    title: "Event Calendar",
    img: img10
}

export const newsHero = {
    title: "Latest News & Updates",
    desc: "Blog",
    img: img11
}

export const adminHero = {
    title: "Leadership Team",
    desc: "Administration",
    img: img12
}