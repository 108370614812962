export const testimonials = [
    {
        review: 'I am very pleased and thankful for the holistic education given by Saint Ignatius schools. I have three children pursuing their education at Saint Ignatius primary and secondary schools from 2013 to date. Overtime, I have been impressed by the schools’ exceptional education approach that recognizes the importance of a child’s social, physical, emotional and spiritual development.',
        name: 'Gilbert Rugiramaza',
        role: 'Parent'
    },
    {
        review: 'SIHS is an all round experience for our children. The focus is not just academics but teaching christian values and raising responsible citizens as well. I congratulate the school management for being a trusted, caring and reliable partner!',
        name: 'Dianah Kwarisiima',
        role: 'Parent'
    },
    // {
    //     review: 'Sit magna anim laboris sint pariatur aliquip voluptate reprehenderit minim nisi labore aute velit aliqua. Amet incididunt eu aliquip elit consequat do mollit aliquip qui. Reprehenderit et dolor minim qui amet. Anim incididunt ullamco ex cupidatat commodo elit. Excepteur adipisicing nisi dolor laborum sint fugiat labore nostrud.',
    //     name: 'John Smith',
    //     role: 'Parent'
    // }
];