import React from 'react';
import './YoutubeEmbed.css';

const YoutubeEmbed = ({ link }) => {
    return (
        <div className='youtube-video'>
            <iframe
                width="100%"
                height="100%"
                src={link.src}
                title={link.title}
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
            />
        </div>
    )
}

export default YoutubeEmbed
