import lunch from '../Images/lunch.jpg';
import running from '../Images/run.jpg';
import clubs from '../Images/clubs.jpg';
import infoTech from '../Images/comp-lab.jpg';
import safety from '../Images/security.jpg';
import science from '../Images/science-lab.jpg';

export const facilities = [
    {
        title: 'Dining On campus',
        img: lunch
    },
    {
        title: 'Athletics',
        img: running
    },
    {
        title: 'Clubs & Extra-Curricular Activities',
        img: clubs
    },
    {
        title: 'Information Technology',
        img: infoTech
    },
    {
        title: 'Science Labs',
        img: science
    },
    {
        title: 'Safety and Security',
        img: safety
    }
];