import React from 'react';
import './Project.css';

const Project = ({ project }) => {
    return (
        <div className='project'>
            <div className='project-img-container'>
                <img src={project.img} alt='Project Cover' className='project-img'/>
            </div>
            <div>
                <h3>{project.name}</h3>
                <p className='project-progress'>{project.progress}</p>
            </div>
        </div>
    )
};

export default Project;
